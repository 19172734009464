// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.evt-ami {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px 0 8px;
}

.table-layout {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.top-thing {
  display: flex;
  flex: .05;
  min-height: 50px;
  padding: 8px 0 0 0;
  justify-content: right;
  align-items: center;
}


.table-container {
  overflow: auto;
  position: relative;
  height: 800px;
}

.table-header {
  display: grid;
  position: sticky;
  padding: 2px 4px;
  text-align: left;
  top: 0;
  z-index: 1;
  box-shadow: 0 4px 6px -4px gray;
}

.tr-border {
  border-bottom: 1px solid lightgray;
}

.evt-cell {
  display: flex;
  align-items: center;
  padding: 6px;
  overflow-wrap: anywhere;
}

.container {
  border: 1px solid lightgray;
  margin: 1rem auto;
}

.hovery-doodle {
}

.hovery-doodle:hover {
  color: #a5aaee;
}

.header-group {
  display: flex;
  width: 100%;
  height: 40px;
}

.basic-th {
  display: flex;
  justify-content: center;
  align-items: center;
}

.basic-tr {
  display: flex;
  position: absolute;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Shotgun/AMI/SGEventHistory.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;AACrB;;;AAGA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;AACA;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;AACb","sourcesContent":["\n.evt-ami {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 8px 0 8px;\n}\n\n.table-layout {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  flex-direction: column;\n}\n\n.top-thing {\n  display: flex;\n  flex: .05;\n  min-height: 50px;\n  padding: 8px 0 0 0;\n  justify-content: right;\n  align-items: center;\n}\n\n\n.table-container {\n  overflow: auto;\n  position: relative;\n  height: 800px;\n}\n\n.table-header {\n  display: grid;\n  position: sticky;\n  padding: 2px 4px;\n  text-align: left;\n  top: 0;\n  z-index: 1;\n  box-shadow: 0 4px 6px -4px gray;\n}\n\n.tr-border {\n  border-bottom: 1px solid lightgray;\n}\n\n.evt-cell {\n  display: flex;\n  align-items: center;\n  padding: 6px;\n  overflow-wrap: anywhere;\n}\n\n.container {\n  border: 1px solid lightgray;\n  margin: 1rem auto;\n}\n\n.hovery-doodle {\n}\n\n.hovery-doodle:hover {\n  color: #a5aaee;\n}\n\n.header-group {\n  display: flex;\n  width: 100%;\n  height: 40px;\n}\n\n.basic-th {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.basic-tr {\n  display: flex;\n  position: absolute;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
