import {
  lazy,
  useState,
  useRef,
  forwardRef,
  useContext,
  useEffect,
  Fragment,
  Suspense
} from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,  
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { BackendAPIClientContext } from "../../../../contexts/BackendAPIClientProvider";
// import { AlertContext } from "../../../../contexts/AlertProvider";
import LoadingIcon  from "../../../../common/components/LoadingIcon";

import { checkErrMsg } from "../../../../common/Tools";

import About from "./About";
import User from "./User";


const UserMenuChoices = {
  "About": {
    shortname: "about",
    triggersreload: false,
    cmp: About,
    x: 400,
    y: 100
  },
  "User Profile": {
    shortname: "userprofile",
    triggersreload: true,
    cmp: User,
    x: 600,
    y: 360
  },
  "Logout": {
    shortname: "logout",
    triggersreload: false,
    cmp: null
  }
}

const ViewChoices = {
  "default": lazy(() => import("../../../../common/components/PageNoExist")),
  "SG Tasks": {
    shortname: "sgtasks",
    cmp: lazy(() => import("../../../Shotgun/TaskView/SGTaskView")),
    wsType: "sg.task"
  },
  "Library": {
    shortname: "library",
    cmp: lazy(() => import("../../../Shotgun/Library/SGLibrary")),
    wsType: "sg.version"
  },
}

const ControlBar = (props) => {
  const theme = useTheme(); 
  const BackendAPI = useContext(BackendAPIClientContext);
  
  // const { active, message, type } = React.useContext(AlertContext);
  

  const [loading, setLoading] = props.loading;
  const [selectedView, setSelectedView] = props.viewSelection;
  const [viewName, setviewName] = useState(null);
  const [authState, oktaAuth] = props.auth;
  
  // State initializations
  const [barLoading, setBarLoading] = useState(true);
  const [user, setUser] = useState({
    auth_user: null,
    sg_user: null
  });

  const [modalConfigs, setModalConfigs] = useState({
    userprofile: null
  });
  
  const [ready, setReady] = useState(false);
  const [reloadToggle, setReloadToggle] = useState({
  });
  
  const [anchorOpt, setAnchorOpt] = useState();
  const [anchorUser, setAnchorUser] = useState();
  
  const [snackOpen, setSnackOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  
  const [usrMenuMsg, setUsrMenuMsg] = useState("");
  const trgRef = useRef({trigger: "false", msg: ""});

  const [currentSelection, setCurrentSelection] = useState();
  const [msg, setMsg] = useState(
    "Something went wrong. Please contact Animtech."
  )
  
  const appMenuItems = Object.keys(ViewChoices).map((i) => {
    if (i !== "default") {
      return i
    } else {
      return null
    }
  }).filter(n => n)

  const userMenuItems = Object.keys(UserMenuChoices).map((i) => {return i});

  useEffect(() => {
    console.log("HOME!")
    if (is_logout_triggered()) {
      console.log("logout triggered");
      logout();
    } else {
      getAuthUser();
    }
  }, [])

  useEffect(() => {
    if (ready) {
      setSelectedView(setCurrentView(null))
      setLoading(false);
      setBarLoading(false);
    }
  },[ready])

  useEffect(() => {
    if (reloadToggle[viewName]) {
      setSelectedView(setCurrentView(viewName))
    }
  },[reloadToggle])


  const is_logout_triggered = () => {
    const logoutTrigger = JSON.parse(
      window.sessionStorage.getItem("TriggerLogout")
    );
    return (authState?.isAuthenticated && logoutTrigger === true)
  }
  
  const handleLogout = () => {
    logout();    
    return null;
  }

  const logout = async () => {
    try {
      await BackendAPI.coreAPIConnect("revoke")
      .then((data) => {
        if (data.revocation === true) {
          oktaAuth.signOut("/login")
        }
      })      
    } catch(err) {
      console.log(err);
    } finally {
      window.sessionStorage.removeItem("TriggerLogout");
    }
  }


  const getAuthUser = async () => {
    await BackendAPI.coreAPIConnect("auth_user", {})
    .then((data) => JSON.parse(data))
    .then((parsed) => {
      const errmsg = checkErrMsg(parsed);
      if (errmsg === null) {
        return parsed.data;
      } else {
        setMsg(
          "We encountered an error while authorizing the user -- " + errmsg
        );
        throw(parsed.errmsg)
      }
    })
    .then(async (data) => {
        await getUserSettings(data);
    })
    .then(() => {
      setReady(true)
    })
    .catch((err) => {
      setUser({...user, sg_user: {}, auth_user: {}});
      setReady(true);
    })
  }

  const getUserSettings = async (user_data) => {
    if (user_data.sg_user_id === null) {
      setMsg(
        "You have not been authorized in Shotgrid to use this App. " +
        "Please contact Animtech."
      )
      setUser({...user, sg_user: {}, auth_user: user_data});
      // can run modal options here if necessary
      // await getModalOptions(user_data);
      return;
    }
    const payload = {
      id: user_data.sg_user_id,
      user_type: user_data.sg_user_type
    }
    await BackendAPI.coreAPIConnect("sg_user_details", payload)
    .then((data) => JSON.parse(data))
    .then((parsed) => {
      const errmsg = checkErrMsg(parsed);
      if (errmsg === null) {
        setUser({...user, sg_user: parsed.data, auth_user: user_data})
        return parsed.data
      } else {
        setMsg(
          "We encountered an error while gathering Shotgrid user data -- " + errmsg
        );
        throw(errmsg)
      }
    })
    .then(async(sg_data) => await getModalOptions(sg_data))
  }

  const getModalOptions = async(data) => {
    /*
    Configurations from the back end need to be keyed to each specific
    modal selection. Whether we do this all in one back end call or 
    multiple, we need to know ahead of time what the modal panel would
    require. IE the User modal will need a list of admin shadow users.
    */

    // Right now we assume there's only sg users. In the future we
    // may want to add modal options for non-sg users. We'd need
    // some checks in order to pass to the right API. The
    // below line is only a precaution to run this for sg users only for now.
   if (data.id === undefined) {
    return
   }

    const payload = {
      id: data.id,
      user_type: data.type
    }
    await BackendAPI.coreAPIConnect("sg_admin_user_list", payload)
    .then((data) => JSON.parse(data))
    .then((parsed) => {
      const errmsg = checkErrMsg(parsed);
      if (errmsg === null) {
        setModalConfigs({...modalConfigs, userprofile: parsed.data});
      } else {
        setMsg(
          "We encountered an error while gathering menu configurations -- " + errmsg
        );
        throw(errmsg)
      }
    })
  }

  const handleTriggerCallback = () => {
    if (trgRef.current.trigger === true) {
      updateView(viewName);
      setUsrMenuMsg(trgRef.current.msg);
      trgRef.current.trigger = false;   // reset trigger
    }
  }

  const postModalCloseEvent = async() => {
    handleTriggerCallback()
    setModalOpen(false)
  }

  const handleClick = (e) => {
    setAnchorOpt(e.currentTarget.getAttribute("id") === "controlbar-views-button" ? e.currentTarget: null);
    setAnchorUser(e.currentTarget.getAttribute("id") === "controlbar-user-button" ? e.currentTarget: null);
  }

  const handleClose = () => {
    setAnchorOpt(null);
    setAnchorUser(null);
  }

  const handleModalClose = () => {
    postModalCloseEvent();
  }

  const whatWasClicked = (e) => {
    if (e !== null) {
      e.preventDefault();
    }
    let choice = e.currentTarget.textContent;

    setCurrentSelection(choice);
    if (checkUserMenuParameters(choice)) {
      setModalOpen(true);
    } else {
      let current_view = setCurrentView(choice)
      if (![null, undefined].includes(current_view)) {
        setviewName(choice);
        setSelectedView(current_view);
        if (choice === currentSelection) {
          updateView(choice);
        }
      }
    }
    handleClose();
  }

  const checkUserMenuParameters = (choice) => {
    let val = UserMenuChoices[choice]
    let idx = Object.keys(UserMenuChoices).indexOf(choice)
    let invalid_test = [null, undefined]
    return (idx !== -1 && !invalid_test.includes(val.cmp))
  }

  // ==========================================================================
  const updateView = (selection) => {
    let val = reloadToggle[selection] === undefined ? "a" : reloadToggle[selection];
    setReloadToggle({[selection]: val === "a" ? "b": "a"})
  }
  
  const panelSelection = (selection) => {
    if (Object.keys(ViewChoices).indexOf(selection) !== -1) {
      return [true, ViewChoices[selection]]
    } else {
      return [false, <PageNoExist pageName={selection} />]
    }    
  }

  const selectDefaultBoard = (selection) => {
    let view = "default"
    if (selection === null) {
      if (user.sg_user.projects) {
        view = "SG Tasks";
        // view = "Library";
      }
    } else {
      view = selection;
    }
    setviewName(view);
    setCurrentSelection(view);
    return view;
  }

  const DefaultBoard = () => {
    return (
      <Box className="mui-box ctrlbar-default-view-parent">
        <Typography>{msg}</Typography>
      </Box>
    )    
  } 

  const setCurrentView = (selected) => {
    const selected_view = selectDefaultBoard(selected);
    let reload = reloadToggle[selected_view] ? reloadToggle[selected_view] : null;
    if (selected_view === "default") {
      return <DefaultBoard />
    } else if (selected_view === "Logout") {
      handleLogout();
    } else {
      const [view_exists, vData] = panelSelection(selected_view)
      if (view_exists === true) {
        const View = vData.cmp;
        return (
          <Suspense fallback={<LoadingIcon />} >
            <View
              key={reload}
              User={user}
              change={"wsState"}
            />
          </Suspense>
        )
      } else {
        return View;
      }
    }    
  }

  // ==========================================================================
  const menuOptions = (options, menuTitle) => {
    return (
      <div>
        <Divider textAlign="left" variant="middle">
          <Typography variant="overline" display="block" sx={{ fontSize: 8 }}>
            {menuTitle}
          </Typography>
        </Divider>
        {options.map((item, idx) => (
          <MenuItem key={idx} onClick={whatWasClicked}>
            <Typography>{item}</Typography>
          </MenuItem>
        ))}
      </div>
    )
  }

  const BarModal = () => {
    const modalOptions = UserMenuChoices[currentSelection];
    if (modalOpen && (modalOptions.cmp !== null)) {
      let ThisModal = forwardRef((props, ref) => (
        <modalOptions.cmp User={user} inner={ref} />
      ));
      if (modalOptions.shortname === "userprofile") {
        let cfgs = modalConfigs[modalOptions.shortname]
        ThisModal = forwardRef((props, ref) => (
          <modalOptions.cmp
            inner={ref}
            User={user}
            configs={cfgs}
          />
      ));
      }
      return (
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          slotProps={{
            backdrop: {
              sx: {
                backdropFilter: "blur(3px)",
                backgroundColor: 'rgba(0, 0, 0, .5)',
              },
            },
          }}          
          aria-labelledby="contentbar-modal-title"
          aria-describedby="contentbar-modal-description"
        >
          <Box className="modal-viewer-parent-frame">
            {<ThisModal ref={trgRef} />}
          </Box>
        </Modal>
      )
    } else {
      return null;
    }
  }

  const barLayout = () => {
    return (
      <Fragment>
        <BarModal />
        <Box
          className="mui-box ctrlbar-layout-parent"
          sx={{backgroundColor: theme.palette.secondary.light}}
        >
          <Box className="mui-box ctrlbar-views-menu-parent">
            <IconButton
              id="controlbar-views-button"
              className="ctrlbar-menu-iconbutton"
              edge="start"
              color="inherit"
              aria-controls="contentbar-menu"
              aria-label="menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <theme.Icons.menuIcon />
            </IconButton>
            <Menu
              id="contentbar-menu-popup"
              anchorEl={anchorOpt}
              keepMounted
              open={Boolean(anchorOpt)}
              onClose={handleClose}
            >
              {user.auth_user.sg_user_id ? menuOptions(appMenuItems, "Shotgrid") : null}
            </Menu>
          </Box>
          <Box className="mui-box ctrlbar-title-parent">
            <Typography variant="atw-header" sx={{color: theme.palette.title.main}}>Anim</Typography>
            <Typography variant="atw-header" sx={{color: theme.palette.title.light}}>Tech</Typography>
          </Box>
          <Box className="mui-box ctrlbar-user-menu-parent">
            <Box className="ctrlbar-user-menu-msgbox">
              <Typography className="italic" variant="msg-active-bold">
                {usrMenuMsg}
              </Typography>
            </Box>
            <IconButton
              id="controlbar-user-button"
              className="ctrlbar-menu-iconbutton"
              variant={usrMenuMsg !== "" ? "msg-active" : null}
              edge="start"
              color="inherit"
              aria-controls="contentbar-menu"
              aria-label="menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <theme.Icons.userIcon />
            </IconButton>
            <Menu
              id="menu-user"
              anchorEl={anchorUser}
              keepMounted
              open={Boolean(anchorUser)}
              onClose={handleClose}
            >
              {menuOptions(userMenuItems, user.auth_user.user_fullname)}
            </Menu>
          </Box>
        </Box>
      </Fragment>
    )
  }

  return barLoading ? null : barLayout()
}

export default ControlBar;
