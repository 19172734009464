//@ts-check
import { LibClientRoutes } from "../Library/LibraryClientRoutes";
import { ReqClientRoutes } from "../Requests/RequestClientRoutes";
import { TVClientRoutes } from "../TaskView/TaskViewClientRoutes";

const CoreSGAspects = {
  sg_user_details : {method: "post", type: "sg", path: "query/user_details"},
  sg_admin_user_list: {method: "post", type: "sg", path: "query/admin_user_list"},
  sg_get_user_shadow_access: {method: "get", type: "sg", path: "query/user_shadow_access"},
  sg_set_user_shadow_access: {method: "post", type: "sg", path: "query/user_shadow_access"}
}

export const SGRoutes = Object.assign(
  {},
  CoreSGAspects,
  LibClientRoutes,
  ReqClientRoutes,
  TVClientRoutes
)