__webpack_nonce__ = window.nonce

import { Fragment, lazy, Suspense } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import { CacheProvider, ThemeProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { Box } from "@mui/material";

import LoadingIcon from './common/components/LoadingIcon';

import { AlertProvider } from './contexts/AlertProvider';
import { BackendAPIClientProvider } from './contexts/BackendAPIClientProvider';
import { SocketProvider } from './contexts/SocketProvider';

import { RequireAuth } from './apps/Main/components/SecureRoute';

import { lightTheme, darkTheme } from './styles/themes';

import { NuThemeProvider } from './contexts/NuThemeProvider';

import Home from './apps/Main/Home';
import Login from './apps/Main/Login';
const SGEvent = lazy(() => import('./apps/Shotgun/Events/SGEvent'));

const cache = createCache({
  key: 'some-dang-key',
  nonce: __webpack_nonce__,
  prepend: true,
});

const storageType = 'sessionStorage';   // or 'localStorage'

const config = {
  issuer: process.env.OKTA_ISSUER,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/oidc/callback',
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  tokenManager: {
    autoRenew: false,
    storage: storageType
  },
//   storageManager: {
//     token: {
//      storageType: storageType,
//      storageTypes: []
//     },
//     cache: {
//      storageType: storageType,
//      storageTypes: []
//    },
//    transaction: {
//      storageType: storageType,
//      storageTypes: []
//    }
//  },
 services: {
  autoRenew: false
 },
  pkce: true
}


const PageNotFound404 = (props) => {
  console.log(window.location.href);
  return (
    <div>
      <h1>404 - Not found</h1>
      <h3>(I promise this will look nice someday.)</h3>
    </div>
  );
};


function App() {
  const oktaAuth = new OktaAuth(config);
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth,  originalUri) => {
    let go_to_this = toRelativeUrl(originalUri || '/', window.location.origin);
    navigate(go_to_this);
  };

  const customAuthHandler = () => {
    console.log('Aww, do you require authorization?')
    navigate('/login');
  };
  

  const light = lightTheme;
  const dark = darkTheme;
  return (
      <Security
        oktaAuth={oktaAuth} 
        restoreOriginalUri={restoreOriginalUri}
        onAuthRequired={customAuthHandler}
      >
        <CacheProvider value={cache}>
          <Box
            id='app-root-container'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              m: -1,
            }}
          >
            <BackendAPIClientProvider>
              {/* <SocketProvider> */}
                <ThemeProvider theme={light}> {/* will eventually be removed in favor of NuThemeProvider */}
                  <NuThemeProvider>
                  <AlertProvider>
                      <Fragment>
                        <Suspense fallback={<LoadingIcon />}>
                          <Routes>
                            <Route path='/' exact={true} element={<RequireAuth />}>
                              {/* <Route path='/dashboard/:page' element={<Home />} /> */}
                              <Route path='' element={<Home />} />
                              <Route path='/sg/:user_id/event/:evt_id' element={<SGEvent />} />
                            </Route>
                            <Route path='/login' element={<Login />} />
                            <Route path='/oidc/callback' element={<LoginCallback />} />
                            <Route path='*' element={<PageNotFound404 />} />
                          </Routes>
                        </Suspense>
                      </Fragment>
                  </AlertProvider>
                  </NuThemeProvider>
                </ThemeProvider>
              {/* </SocketProvider> */}
            </BackendAPIClientProvider>
          </Box>
        </CacheProvider>
      </Security>  
  )
}

export default App;
