/*
Using this is a convenient way to provide GLOBAL mui styles. This 
will not apply to individual components that may need specific style
on a per-view basis.

However, unless it is necessary to the look, any and all color values
used (both light and dark mode) should come from this theme provider.

My intention here is to drive color, font type/size/color and global
configurations for certain components. Be aware that size/shape/behavior
will be configured via a view-specific stylesheet that exists with the
view components. 

*/


// Anim Tech Style sheet
import { createTheme } from "@mui/material/styles";
import { grey, blue } from '@mui/material/colors';

// Icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
// import SortIcon from '@mui/icons-material/Sort';

// Internal svg files
import BigN from '../../static/images/big-n.svg';
import AtwLogo from '../../static/images/atw_logo.svg';
import NickDog from '../../static/images/dog_16x24.svg';
import FileDog from '../../static/images/fileDog_16x16.svg';


// Colors for themes
const titleMain = '#F78F1E';
const titleLight = '#AAC1D3';

const warningMain = '#494949';
const warningContent = titleMain;
const errorMain = '#EF5350';
const successMain = '#8DB2CF';
const infoMain = '#0288D1';

const lightPrimaryMain = '#F6FBFF';
const lightPrimaryContent = '#D5E5F0';
const lightPrimaryContentHover = '#EEF6FB';
const lightPrimaryContentFocus = '#A6B8C3';
const lightPrimaryContentAlt = '#CED9E1';
const lightSecondaryMain = '#E2EBF0';
const lightSecondaryLight = '#EDF5FC';
const lightSecondaryMedium = '#e0ebf4';
const lightSecondaryDark = '#7e97ad';
const lightSecondaryContent = '#EDF0F6';
const lightSecondaryContentAlt = '#B7C1C9';

const lightPrimaryLight = '#FBFDFF';    // #F4F9FD
const lightLoginMain = '#FFEEDB';
const lightLoginLight = '#DFD0BD';
const lightContrastText = '#313D45';
const lightBoldMain = '#87c9ed';

const lightBoxPrimaryMain = '#bcc8d1';
const lightBoxPrimaryLight = '#9faab4'

const lightButtonLabel = '#a8c0d0'

const darkPrimaryMain = '#4B5B67';
const darkPrimaryLight = '#738C9D';
const darkSecondaryMain = '#80776E';
const darkContrastText = '#E2EBF0';
const darkBoldMain = '#ED8C3A';

const errorImg = 'rgba(155, 169, 227, 0.8)'

// ATW Font Family
const atwDefault = "'Lato', sans-serif";
// const atwHeader = "'Palanquin Dark', sans-serif";
const atwHeader = "'Palanquin', sans-serif";


// ============================================================================
// ===== Light ================================================================
export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: lightPrimaryMain,
            light: lightPrimaryLight,
            content: lightPrimaryContent,
            contentHover: lightPrimaryContentHover,
            contentFocus: lightPrimaryContentFocus,
            contentAlt: lightPrimaryContentAlt,
            contrastText: lightContrastText,
            loginMain: lightLoginMain,
            loginLight: lightLoginLight,
        },
        secondary: {
            main: lightSecondaryMain,
            light: lightSecondaryLight,
            medium: lightSecondaryMedium,
            dark: lightSecondaryDark,
            content: lightSecondaryContent,
            contentAlt: lightSecondaryContentAlt,
            contrastText: lightContrastText,
        },
        warning: {
            main: warningMain,
            content: warningContent,
        },
        error: {
            main: errorMain,
            errorImg: errorImg
        },
        success: {
            main: successMain
        },
        info: {
          main: lightContrastText,
          light: infoMain,
        },
        title: {
            main: titleMain,
            light: titleLight,
            white: "#fff"
        },
        dropdown: {
            main: grey['500'],
        },
        icon: {
            on: successMain,
            off: infoMain
        },
        borders: {
            active: "#57a5ee",
            inactive: "#878e95"
        },
        greys: {
            light: grey["300"],
            four: grey["400"],
            medium: grey["500"],
            six: grey["600"],
            dark: grey["800"]
        },
    },
    spacing: 4,
    components: {
        MuiTypography: {
            defaultProps: {
                className: 'prevent-select'
            },
            styleOverrides: {
                root: {
                    fontFamily: atwDefault,
                    color: lightContrastText
                }
            },
            variants: [
                {
                    props: {variant: "atw-header"},
                    style: {
                        fontFamily: atwHeader,
                        fontSize: 24,
                        fontWeight: 400,
                    }
                },
                {
                    props: {variant: "label-bold"},
                    style: {
                        fontSize: 14,
                        fontWeight: 600,
                    }
                },
                {
                    props: {variant: "msg-active-bold"},
                    style: {
                        fontSize: 13,
                        fontWeight: 800
                    }
                },
                {
                    props: {variant: "nick-text"},
                    style: {
                        color: titleMain,
                        "&:hover": {color: titleLight}
                    }
                }
            ],
        },

        // Button Components
        MuiIconButton: {
            variants: [
                {
                    props: {variant: 'msg-active'},
                    style: {
                        border: `2px solid ${successMain}`
                    }
                }
            ]
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'highlight-label'},
                    style: {
                        backgroundColor: "inherit",
                        color: lightButtonLabel,
                        fontSize: 14,
                        fontWeight: 400,
                        textTransform: "none"
                    },                    
                },                
                {
                    props: { variant: 'example-a' },
                    style: {
                    textTransform: 'none',
                    border: '2px dashed blue',
                    },
                },
                {
                    props: { variant: 'example-b', color: 'secondary' },
                    style: {
                    border: '4px dashed red',
                    },
                },
            ],
            styleOverrides: {
                root: {
                    color: lightContrastText,
                    backgroundColor: lightBoldMain,
                }
            }
        },

        // Checkboxes
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: successMain
                    }
                }
            }
        },

        // Select Menu
        MuiSelect: {
            styleOverrides: {
                root: {
                    minWidth: 10,
                }
            }
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    backgroundColor: lightPrimaryLight
                }
            }
        },

        MuiModal: {
            styleOverrides: {
                root: {
                    m: 5
                }
            }
        },
    },
    ItemButton: {
        default: {
            backgroundColor: '#F5F6FA',
            border: "1px solid",
            borderColor: grey['300']
          },
          hover: {
            backgroundColor: '#f6f7fb',
            borderColor: blue['200']
          },
          focus: {
            backgroundColor: '#E8ECF5',
            borderColor: grey['500'],
            boxShadow: 2,
            "&:hover": null
          }
    },
    Icons: {
        timeIcon: AccessTimeIcon,
        filterIcon: FilterAltOutlinedIcon,
        searchIcon: SearchIcon,
        // sortIcon: SortIcon,
        sortIcon: FilterListIcon,
        clearIcon: ClearIcon,
        menuIcon: MenuIcon,
        groupIcon: GroupIcon,
        userIcon: PersonIcon
    },
    Images: {
        bigN: BigN,
        atwLogo: AtwLogo,
        nickDog: NickDog,
        fileDog: FileDog
    }
});

// ============================================================================
// ===== Dark =================================================================not
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: darkPrimaryMain,
            light: darkPrimaryLight,
            contrastText: darkContrastText,
        },
        secondary: {
            main: darkSecondaryMain,
            contrastText: darkContrastText,
        },
        warning: {
            main: warningMain
        },
        // error: {
        //     main: errorMain
        // },
        success: {
            main: successMain
        },
        info: {
            main: infoMain
        }

    },
    spacing: 4,
    components: {

        MuiTypography: {
            styleOverrides: {
                root: {
                    color: darkContrastText
                }
            }
        },

        // Button Components
        MuiButton: {
            styleOverrides: {
                root: {
                    color: darkContrastText,
                    backgroundColor: darkBoldMain,
                }
            }
        },

        // Checkboxes
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: successMain
                    }
                }
            }
        },

        // Select Menu
        MuiSelect: {
            styleOverrides: {
                root: {
                    minWidth: 10,
                }
            }
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    backgroundColor: darkPrimaryLight
                }
            }
        }
    }
});